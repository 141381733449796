import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "components/transition-link"
import Star from "assets/star.svg"
import Arrow from "assets/arrow.svg"
import FadeIn from "transitions/fade-in"

const OpenPositions = () => {
  const { wpPage, allWpOpenPosition } = useStaticQuery(graphql`
    query CareersCurrentOpenings {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          openPositions {
            heading
            content
            noPositions {
              heading
              content
            }
          }
        }
      }
      allWpOpenPosition(sort: { order: ASC, fields: date }) {
        roles: nodes {
          title
          slug
          databaseId
          post: openPositionPost {
            externalLink
            location
            type
          }
        }
      }
    }
  `)
  const { heading, content } = wpPage.careers.openPositions
  const { roles } = allWpOpenPosition

  return (
    <section
      className="my-20 md:my-30 lg:my-36 sm:pt-10 o-container"
      id="open-positions"
    >
      <div className="mx-auto max-w-1xl lg:max-w-full">
        <div className="md:px-7">
          <div className="-ml-16 lg:flex">
            <div className="pl-16 mb-12 text-center lg:w-1/2 lg:mb-0 lg:text-left">
              <FadeIn delay="0">
                <h3 className="mb-8 font-serif lg:mb-18 text-38px">
                  {heading}
                </h3>
              </FadeIn>
              <FadeIn delay="200">
                <div
                  className="style-links text-midnight"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </FadeIn>
            </div>

            <div className="pl-10 lg:w-1/2 c-current-openings">
              {roles &&
                roles.length > 0 &&
                roles.map((role, i) => (
                  <FadeIn key={`role-${role.databaseId}`} delay={`${i * 50}`}>
                    <Link
                      className="justify-center text-center c-current-openings__item md:text-left md:justify-between"
                      to={
                        role.post.externalLink &&
                        role.post.externalLink.length > 0
                          ? role.post.externalLink
                          : `/careers/${role.slug}/`
                      }
                      target={
                        role.post.externalLink &&
                        role.post.externalLink.length > 0
                          ? "_blank"
                          : ""
                      }
                    >
                      <div className="c-current-openings__text">
                        <h3 className="font-serif font-light text-cow text-midnight">
                          {role.title}
                        </h3>
                        <div className="flex items-center justify-center mt-1 leading-normal md:justify-start md:mt-4">
                          <div>{role.post.type}</div>
                          <div>
                            <Star className="w-2 mx-3 mt-px" />
                          </div>
                          <div>{role.post.location}</div>
                        </div>
                      </div>
                      <div className="hidden c-current-openings__arrow md:block">
                        <Arrow />
                      </div>
                    </Link>
                  </FadeIn>
                ))}
              {!roles ||
                (!roles.length > 0 && (
                  <div>
                    <h4>No current openings</h4>
                    <p>Check back another time for available positions</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OpenPositions
