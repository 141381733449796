import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { renderImage } from "utils/imageRender"
import TwoColumns from "layouts/two-columns"
import Star from "assets/star.svg"
import FadeIn from "transitions/fade-in"

const NeverStopGrowing = () => {
  const { wpPage } = useStaticQuery(graphql`
    query CareersNeverStopGrowing {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          grow {
            heading
            content
            testimonial {
              quote
              role
              staffMember
            }
            image {
              ...FluidHalfWidthImage
            }
          }
        }
      }
    }
  `)
  const { heading, content, testimonial, image } = wpPage.careers.grow

  return (
    <TwoColumns
      image={renderImage(image)}
      text={
        <div className="mx-auto text-center max-w-1xl md:max-w-md md:text-left">
          <FadeIn delay="0">
            <h3 className="mb-8 font-serif text-38px">{heading}</h3>
          </FadeIn>
          <FadeIn delay="100">
            <div
              className="mb-8 md:mb-12 lg:mb-18"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </FadeIn>

          {testimonial.quote && (
            <FadeIn delay="250">
              <div className="mt-6">
                <div
                  className="mx-auto mb-8 border-t md:ml-0 border-iron"
                  style={{ width: "86px" }}
                ></div>
                <div
                  className="font-serif text-xl tracking-tight"
                  style={{ lineHeight: "1.5" }}
                >
                  “{testimonial.quote}”
                </div>
                <div className="flex items-center justify-center mt-4 text-sm md:justify-start text-iron">
                  <span className="font-semibold text-squirrel text-midnight">
                    {testimonial.staffMember}
                  </span>
                  <span className="mx-3" style={{ width: "6px" }}>
                    {" "}
                    <Star />{" "}
                  </span>
                  <span
                    className="font-semibold text-12px"
                    style={{ lineHeight: "1" }}
                  >
                    {testimonial.role.toUpperCase()}
                  </span>
                </div>
              </div>
            </FadeIn>
          )}
        </div>
      }
    />
  )
}

export default NeverStopGrowing
