import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FadeIn from "transitions/fade-in"

const HiringProcess = () => {
  const { wpPage } = useStaticQuery(graphql`
    query HiringProcess {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          hiringProcess {
            heading
            faqs {
              question
              answer
            }
          }
        }
      }
    }
  `)
  const { heading, faqs } = wpPage.careers.hiringProcess

  return (
    <section className="my-20 sm:mb-22 md:mt-28 lg:mt-36 o-container text-center lg:text-left">
      <div className="md:px-7">
        <div className="lg:flex">
          <div className="lg:w-1/2">
            <FadeIn>
              <h2 className="mb-12 md:mb-8 font-serif lg:max-w-xs lg:mb-22 text-40px">
                {heading}
              </h2>
            </FadeIn>
          </div>
          <div className="lg:pl-2 lg:w-1/2">
            {faqs.map(({ question, answer }, i) => (
              <FadeIn delay={`${i * 50}`} key={`faq-${i}`}>
                <div className="mb-12 sm:mb-14 px-5 lg:px-0">
                  <h3 className="mb-4 md:mb-5 text-lg md:text-xl font-semibold leading-normal">
                    {question}
                  </h3>
                  <div
                    className="style-links style-links--midnight"
                    dangerouslySetInnerHTML={{ __html: answer }}
                  ></div>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HiringProcess
