import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FadeIn from "transitions/fade-in"

const Benefits = () => {
  const { wpPage } = useStaticQuery(graphql`
    query Benefits {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          benefits {
            heading
            benefits: benefitsRepeater {
              heading
              description
              icon {
                altText
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const { heading, benefits } = wpPage.careers.benefits

  return (
    <section className="my-24 sm:my-16 md:pt-10 o-container lg:mb-46">
      <div className="md:px-7">
        <FadeIn>
          <h2 className="mb-16 font-serif text-center md:mb-18 lg:mb-22 text-38px">
            {heading}
          </h2>
        </FadeIn>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-12 sm:gap-y-8 gap-8 text-center lg:text-left sm:gap-10 md:gap-20 lg:grid-cols-3">
          {benefits.map((benefit, i) => (
            <FadeIn key={`benefit-${benefit.heading}`} delay={`${i * 50}`}>
              {benefit.icon && (
                <img
                  className="block h-12 mx-auto lg:mx-0 mb-6"
                  draggable="false"
                  alt={benefit.icon.altText}
                  src={benefit.icon.sourceUrl}
                />
              )}
              <h3 className="mb-2 font-serif text-cow">{benefit.heading}</h3>
              <p>{benefit.description}</p>
            </FadeIn>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Benefits
