import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { renderImage } from "utils/imageRender"
import FadeIn from "transitions/fade-in"

const WorkFromAnywhere = () => {
  const { wpPage } = useStaticQuery(graphql`
    query CareersWorkFromAnywhere {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          workFromAnywhere {
            testimonial {
              quote
              role
              staffMember
            }
            heading
            content
            image {
              ...FluidHalfWidthImage
            }
          }
        }
      }
    }
  `)
  const {
    heading,
    content,
    testimonial,
    image,
  } = wpPage.careers.workFromAnywhere

  return (
    <section className={`my-20 md:my-30 lg:my-36 md:pt-10 c-text-and-image`}>
      <div className="md:container">
        <div className="flex flex-wrap items-center justify-center lg:justify-between md:-ml-12 lg:items-start">
          <div className="w-full md:order-1 md:pl-7 md:w-1/2 lg:w-6/12">
            {renderImage(image)}
          </div>

          <div className="mt-12 text-center md:mt-0 md:pl-12 md:mb-12 md:w-1/2 lg:w-5/12 md:mb-0 md:text-left">
            <div className="md:max-w-md lg:mt-16 lg:pl-7">
              <div className="px-5 mx-auto md:px-0 max-w-1xl lg:max-w-full">
                <FadeIn delay="0">
                  <h3 className="mb-8 font-serif text-38px">{heading}</h3>
                </FadeIn>
                <FadeIn delay="100">
                  <div
                    className="md:max-w-sm"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </FadeIn>

                {testimonial.quote && (
                  <FadeIn>
                    <div>
                      <div className="font-serif text-xl">
                        “{testimonial.quote}”
                      </div>
                      <div>
                        {testimonial.staffMember} * {testimonial.role}
                      </div>
                    </div>
                  </FadeIn>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkFromAnywhere
