import React, { PureComponent } from "react"
import { Waypoint } from "react-waypoint"
import { renderImage } from "utils/imageRender"

export default class Gallery extends PureComponent {
  constructor(props) {
    super(props)

    this.onEnter = this.onEnter.bind(this)

    this.duration = this.props.duration || "300"
    this.delay = this.props.delay || 150
    this.ease = this.props.ease || "ease-in"

    this.state = {
      waypointActive: true,
      animate: false,
    }
  }

  componentWillUnmount() {
    this.setState({
      waypointActive: false,
      animate: false,
    })
  }

  onEnter() {
    this.setState({
      waypointActive: false,
      animate: true,
    })
  }

  render() {
    const { name, gallery } = this.props
    let className = `c-gallery ${name || ""} ${
      this.state.animate ? "animate" : ""
    }`

    return (
      <>
        {this.state.waypointActive && (
          <Waypoint onEnter={this.onEnter} scrollableAncestor={"window"} />
        )}
        <div className={className}>
          {gallery.map(({ image }, i) => (
            <div className="c-gallery__image" key={`${name}-${i}`}>
              <div>{renderImage(image)}</div>
            </div>
          ))}
        </div>
      </>
    )
  }
}
