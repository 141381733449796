import React from "react"

const TwoColumns = props => (
  <section
    id={props.id}
    className={`c-text-and-image my-20 md:my-28 lg:my-36 ${props.className ||
      ""}`}
  >
    <div className="md:container">
      <div className={props.reverse ? "lg:pl-7" : "lg:pr-7"}>
        <div className={`grid md:grid-cols-2 items-center gap-12 md:gap-30`}>
          <div className={props.reverse ? "md:order-1 lg:-ml-7" : "lg:-mr-7"}>
            {props.image}
          </div>
          <div className="flex flex-col justify-center px-5 md:px-0">
            <div
              className={`mb-3 mt-6 md:mt-0 ${
                props.reverse ? "lg:mr-7" : "lg:ml-7"
              }`}
            >
              {props.text}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TwoColumns
