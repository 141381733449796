import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import Hero from "components/careers/hero"
import Intro from "components/careers/intro"
import NeverStopGrowing from "components/careers/never-stop-growing"
import WorkFromAnywhere from "components/careers/work-from-anywhere"
import CurrentOpenings from "components/careers/current-openings"
import Benefits from "components/careers/benefits"
import Divider from "components/divider"
import HiringProcess from "components/careers/hiring-process"

const Careers = React.memo(({ data: { wpPage } }) => {
  if (!wpPage) return null

  const { seo } = wpPage

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--careers-listing",
        }}
      />
      <Hero />
      <Intro />
      <NeverStopGrowing />
      <WorkFromAnywhere />
      <CurrentOpenings />
      <Benefits />
      <Divider />
      <HiringProcess />
    </Transition>
  )
})

export const query = graphql`
  query Careers {
    wpPage(databaseId: { eq: 3859 }) {
      ...SEO
    }
  }
`

export default Careers
