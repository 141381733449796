import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FadeIn from "transitions/fade-in"
import { renderImage } from "utils/imageRender"
import Gallery from "components/gallery"

const AboutTogether = () => {
  const { wpPage } = useStaticQuery(graphql`
    query CareersAboutTogether {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          aboutTogether {
            heading
            content
            gallery {
              image {
                ...FluidHalfWidthImage
              }
            }
          }
        }
      }
    }
  `)
  const { heading, content, gallery } = wpPage.careers.aboutTogether

  return (
    <>
      <div className="mb-20 md:mb-26 lg:mb-40 xl:mb-50">
        <div className="container">
          <Gallery gallery={gallery} name="careers-hero" />
        </div>
      </div>
      <div className="o-container">
        <div className="mx-auto text-center md:px-7 max-w-1xl">
          <div className="px-5 md:px-0">
            <FadeIn delay="0">
              <h2 className="mb-8 font-serif text-38px">{heading}</h2>
            </FadeIn>
            <FadeIn delay="100">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </FadeIn>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutTogether
