import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Overline from "components/overline"
import FadeIn from "transitions/fade-in"

const Hero = () => {
  const { wpPage } = useStaticQuery(graphql`
    query CareersHero {
      wpPage(databaseId: { eq: 3859 }) {
        careers {
          careersHero {
            overline
            heading
          }
        }
      }
    }
  `)
  const { overline, heading } = wpPage.careers.careersHero

  return (
    <div className="container c-hero">
      <div className="mx-auto text-center">
        <FadeIn delay="0">
          <Overline>{overline}</Overline>
        </FadeIn>
        <FadeIn delay="200">
          <div className="font-serif text-72px">{heading}</div>
        </FadeIn>
      </div>
    </div>
  )
}

export default Hero
